import React, {useContext, useState} from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {DataContext} from "../context/dataContext";
import {round} from "../utils/converters";
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    heading: {
        fontWeight: 500,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    content: {
        fontWeight: 400,
        fontSize: 16,
        wordBreak:"break-all",
        textAlign:"center",
        paddingLeft:"2%",
        whiteSpace: 'normal',   // new style
        wordWrap: 'break-word',  // new style
        maxHeight: 'none',  // new style
        minHeight: '50px',  // new style
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    cellItem: {
        height: "auto",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        marginBottom: "2px",
        borderBottom:"2px solid #90AEC2"
    },
    cellItemFilled: {
        height: "auto",
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        marginBottom: "2px",
        borderBottom:"2px solid #90AEC2"
    },

    itemContainer: {
        padding: "2% 1%"
    }
}))


const getBackgroundColor = (percentile) => {
    return percentile >= 80 ? '#c6f5c4' : '#f7bebe';
  };

function Summary({ isPridict2Selected }) {
    const classes = useStyles();

    const {summaryData} = useContext(DataContext);

    const firstTitle = isPridict2Selected ? "PRIDICT2.0 MMR- (HEK293T) Score" : "PRIDICT Score - Editing";
    const secondTitle = isPridict2Selected ? "PRIDICT2.0 MMR+ (K562) Score" : "PRIDICT Score - Unintended Editing";

    const firstKey = isPridict2Selected ? "PRIDICT2_0_editing_Score_deep_HEK" : "PRIDICT_editing_Score_deep";
    const secondKey = isPridict2Selected ? "PRIDICT2_0_editing_Score_deep_K562" : "PRIDICT_unintended_Score_deep";
    console.log('firstTitle', firstTitle)
    console.log('isPridict2Selected', isPridict2Selected)
    console.log('secondTitle', secondTitle)
    const [showMoreSummaryInfo, setShowMoreSummaryInfo] = useState(false);

    return (
        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
            <div>
                <Typography variant={"h4"} >
                    Summary <AiOutlineInfoCircle />
                </Typography>
            </div>


            <Button 
                variant="text" 
                startIcon={<ArrowDropDownIcon style={{ transform: showMoreSummaryInfo ? 'rotate(180deg)' : 'rotate(0deg)' }}/>}
                style={{ color: 'grey', marginBottom: '0rem', textTransform: 'none' }}
                disableRipple
                onClick={() => setShowMoreSummaryInfo(!showMoreSummaryInfo)}
            >
                <Typography variant="body2">
                    More info
                </Typography>
            </Button>
            <Collapse in={showMoreSummaryInfo}>
                <Typography variant="body1" style={{ marginTop: '0.5rem', marginBottom: '1rem', textAlign: 'center', padding: '0 10%' }}>
                    Summary of main characteristics of the previously selected pegRNA.<br /><br /> For cloning the selected pegRNA, we refer to Anzalone et al. 2019.<br />
                    The sequence of the final pegRNA (spacer + optimized scaffold + extension, but still lacking a stabilizing motif such as tevopreQ1) after cloning is available when downloading full results ("DOWNLOAD RESULTS" button above).<br />
                    • <span style={{ fontWeight: 500 }}>MMR- (HEK293T) Score</span> should be used <span style={{ fontWeight: 500 }}>only</span> in mismatch-repair deficient conditions (e.g., HEK293T) or when <span style={{ fontWeight: 500 }}>strong and effective</span> MMR inhibition is achieved.<br />
                    • <span style={{ fontWeight: 500 }}>MMR+ (K562) Score</span> is the preferred choice for most cases, including mismatch-repair proficient conditions (e.g., K562, U2OS, HepG2, HeLa, iPSCs, …) without MMR inhibition or when inhibition may not be fully effective, as sometimes occurs with transient methods (e.g., MLH1dn/PE4).<br />
                    If you are unsure about the efficiency of MMR inhibition in your setup, <span style={{ fontWeight: 500 }}>use the MMR+ (K562) Score.</span><br />
                </Typography>
            </Collapse>
            
            
            
            
            <Paper elevation={5} style={{margin: "3% 5%", paddingTop: "2%"}}>
                <Grid container>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.itemContainer}>
                        <Grid container>

                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>{firstTitle}</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? round(summaryData.rna[firstKey], 2) : ""}

                                </Typography>
                            </Grid>
                            {isPridict2Selected && (
                                <>
                                    <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                        <Typography className={classes.heading}>HEK293T - Percentile of Library Diverse</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}
                                            style={{ backgroundColor: summaryData.rna ? getBackgroundColor(round(summaryData.rna["HEK_percentile_to_librarydiverse"], 0)) : '#FFF' }}>
                                        <Typography className={classes.content}>
                                        {summaryData.rna ? round(summaryData.rna["HEK_percentile_to_librarydiverse"], 0) : ""}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {/*--------------------------------------------*/}

                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>{secondTitle}</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? round(summaryData.rna[secondKey], 2) : ""}

                                </Typography>
                            </Grid>
                            {isPridict2Selected && (
                                <>
                                    <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                        <Typography className={classes.heading}>K562 - Percentile of Library Diverse</Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}
                                            style={{ backgroundColor: summaryData.rna ? getBackgroundColor(round(summaryData.rna["K562_percentile_to_librarydiverse"], 0)) : '#FFF' }}>
                                        <Typography className={classes.content}>
                                        {summaryData.rna ? round(summaryData.rna["K562_percentile_to_librarydiverse"], 0) : ""}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>Spacer</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["Protospacer-Sequence"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>PBS (RV)</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["PBSrevcomp13bp"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>RTT (RV)</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["RTrevcomp"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}

                            {!isPridict2Selected && (
                                <>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>Spacer - Oligo FW</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["Protospacer-Oligo-FW"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>Spacer - Oligo RV</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["Protospacer-Oligo-RV"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>Extension - Oligo FW</Typography>

                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["Extension-Oligo-FW"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>Extension - Oligo RV</Typography>

                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.rna ? summaryData.rna["Extension-Oligo-RV"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}
                            </>
                            )}

                        </Grid>
                    </Grid>


                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.itemContainer}>
                        <Grid container>

                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    Nicking guide - DeepSpCas9 score
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.nick ? round(summaryData.nick["DeepCas9score"], 1) : ""}
                                </Typography>
                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    PE3b?
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.nick ? summaryData.nick["PE3b"] === "PE3b" ? "Yes" : "No" : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    Nick Position to edit
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.nick ? summaryData.nick["Nicking-Position-to-edit"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    Nicking guide - Oligo FW
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.nick ? summaryData.nick["Nicking-Proto-Oligo-FW"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    Nicking guide - Oligo RV

                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.nick ? summaryData.nick["Nicking-Proto-Oligo-RV"] : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    NGS PCR1 FW
                                    (Primer3)
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {
                                    summaryData.primer 
                                    ? 
                                        summaryData.primer["PRIMER_LEFT_0_SEQUENCE"] || "NULL"
                                    : 
                                    ""
                                    }
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    NGS PCR1 RV (Primer3)

                                </Typography>

                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.primer ? summaryData.primer["PRIMER_RIGHT_0_SEQUENCE"] || "NULL" : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItem}>
                                <Typography className={classes.heading}>
                                    PCR1 length (bp)
                                </Typography>

                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={6} className={classes.cellItemFilled}>
                                <Typography className={classes.content}>
                                    {summaryData.primer ? summaryData.primer["PRIMER_PAIR_0_PRODUCT_SIZE"] || "NULL" : ""}
                                </Typography>

                            </Grid>
                            {/*--------------------------------------------*/}


                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        </div>
    );
}

export default Summary;

import {useContext, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";
import {DataContext} from "../context/dataContext";
import {round} from "../utils/converters";
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsArrowDownCircle } from 'react-icons/bs'
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';





const useStyles = makeStyles(theme => ({

    root: {
        // overflowY: "auto",
        height: "400px",
    },

    table: {
        minWidth: 650,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "purple"
        }
    },
    tableCell: {
        "$selected &": {
            color: "yellow"
        }
    },
    selected: {},
    tableHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: "#000",

        borderBottom:"2px solid #90AEC2",
    },
    clickableHeader: {
        cursor: 'pointer',
    },

}));

const BlackCheckbox = withStyles({
    root: {
      color: 'black',
      '&$checked': {
        color: 'black',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


function RNATable({ isPridict2Selected }) {

    const {
        rnaData, setRnaData,
        setShowNickingTable,
        showNickingTable,
        setSummaryData,
        setSelectedRNA,
        getZipFile

    } = useContext(DataContext);


    // New sorting logic
    const [sortConfig, setSortConfig] = useState({ 
        key: isPridict2Selected ? 'PRIDICT2_0_editing_Score_deep_K562' : 'PRIDICT_unintended_Score_deep', 
        direction: 'desc' 
      });

    const sortedData = [...rnaData?.data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'desc';
        if (sortConfig.key === key && sortConfig.direction === 'desc') {
          direction = 'asc';
        }
        setSortConfig({ key, direction });
      };
    const handleClickPRIDICT = HEK_rank => {

        let copy = [...rnaData.data]
        const index = copy.findIndex((item) => item.HEK_rank === HEK_rank);

        setSelectedRNA(copy[index])

        copy = copy.map((e, i) => {
            if (e.selected) {
                return {...e, selected: false}
            }
            return i === index ? {...e, selected: true} : {...e, selected: false}
        })

        if (copy[index].selected) {
            setShowNickingTable(true)
            setSummaryData(prev => ({...prev, rna: copy[index]}))
        } else {
            setShowNickingTable(false)
            setSummaryData(prev => ({...prev, rna: null}))
        }

        setRnaData(prev => ({...prev, data: copy}))


    }

    const [showMMRSuggestion, setShowMMRSuggestion] = useState(false);

    const firstKey = isPridict2Selected ? 'PRIDICT2_0_editing_Score_deep_HEK' : 'PRIDICT_editing_Score_deep';
    const secondKey = isPridict2Selected ? 'PRIDICT2_0_editing_Score_deep_K562' : 'PRIDICT_unintended_Score_deep';
    console.log("First Key: ", firstKey);
    console.log("Second Key: ", secondKey);
    console.log("isPridict2Selected: ", isPridict2Selected);


    const classes = useStyles();
    const theme = useTheme();
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0 auto 5% auto"
        }}>
            <Tooltip title={<Typography fontSize={30} align="center">Choose a pegRNA from the list below and then scroll down for nicking guide and summary table.</Typography>} arrow placement="top">
                <div>
                    <Typography variant={"h4"} style={{margin: "10% 0 2% 0"}}>
                        pegRNA <AiOutlineInfoCircle />
                    </Typography>
                </div>
            </Tooltip>
            <Button 
                variant="text" 
                startIcon={<ArrowDropDownIcon style={{ transform: showMMRSuggestion ? 'rotate(180deg)' : 'rotate(0deg)' }}/>}
                style={{ color: 'grey', marginBottom: '0rem', textTransform: 'none' }}
                disableRipple
                onClick={() => setShowMMRSuggestion(!showMMRSuggestion)}
            >
                <Typography variant="body2">
                    Click for more info to choose MMR+/- score
                </Typography>
            </Button>
            <Collapse in={showMMRSuggestion}>
                <Typography variant="body1" style={{ marginTop: '0.5rem', marginBottom: '1rem', textAlign: 'center', padding: '0 10%' }}>
                    • <span style={{ fontWeight: 500 }}>MMR- (HEK293T) Score</span> should be used <span style={{ fontWeight: 500 }}>only</span> in mismatch-repair deficient conditions (e.g., HEK293T) or when <span style={{ fontWeight: 500 }}>strong and effective</span> MMR inhibition is achieved.<br />
                    • <span style={{ fontWeight: 500 }}>MMR+ (K562) Score</span> is the preferred choice for most cases, including mismatch-repair proficient conditions (e.g., K562, U2OS, HepG2, HeLa, iPSCs, …) without MMR inhibition or when inhibition may not be fully effective, as sometimes occurs with transient methods (e.g., MLH1dn/PE4).<br />
                    If you are unsure about the efficiency of MMR inhibition in your setup, <span style={{ fontWeight: 500 }}>use the MMR+ (K562) Score.</span><br />
                </Typography>
            </Collapse>
            <br/>
            <TableContainer component={Paper} className={classes.root}>

                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="center">Select</TableCell>

                            <TableCell 
                                className={`${classes.tableHeader} ${classes.clickableHeader}`} 
                                align="center"
                                onClick={() => handleSort(firstKey)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Tooltip 
                                        title={
                                            <Typography fontSize={30} align="center">
                                                {isPridict2Selected 
                                                    ? "PRIDICT2.0 MMR- (HEK293T) score for editing of different pegRNAs. High score correlate with high editing rates." 
                                                    : "PRIDICT Score for editing of different pegRNAs. High score correlate with high editing rates."
                                                }
                                            </Typography>
                                        } 
                                        arrow 
                                        placement="top"
                                    >
                                        <span>
                                            {isPridict2Selected ? (
                                                <>
                                                    PRIDICT2.0 MMR-<br/>(HEK293T) Score
                                                </>
                                            ) : "PRIDICT Editing"} &nbsp;
                                        </span>
                                    </Tooltip>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <span style={{ color: sortConfig.key === firstKey && sortConfig.direction === 'asc' ? 'black' : 'lightgrey', lineHeight: '0.9' }}>▲</span>
                                            <span style={{ color: sortConfig.key === firstKey && sortConfig.direction === 'desc' ? 'black' : 'lightgrey', lineHeight: '0.9' }}>▼</span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell 
                                className={`${classes.tableHeader} ${classes.clickableHeader}`} 
                                align="center"
                                onClick={() => handleSort(secondKey)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Tooltip 
                                        title={
                                            <Typography fontSize={30} align="center">
                                                {isPridict2Selected 
                                                    ? "PRIDICT2.0 MMR+ (K562) score for editing of different pegRNAs. High score correlate with high editing rates."
                                                    : "PRIDICT Score for unintended editing of different pegRNAs. High score correlate with high unintended editing rates."
                                                }
                                            </Typography>
                                        } 
                                        arrow 
                                        placement="top"
                                    >
                                        <span>
                                            {isPridict2Selected ? (
                                                <>
                                                    PRIDICT2.0 MMR+<br/>(K562) Score
                                                </>
                                            ) : "PRIDICT Unintended Editing"} &nbsp;
                                        </span>
                                    </Tooltip>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <span style={{ color: sortConfig.key === secondKey && sortConfig.direction === 'asc' ? 'black' : 'lightgrey', lineHeight: '0.9' }}>▲</span>
                                            <span style={{ color: sortConfig.key === secondKey && sortConfig.direction === 'desc' ? 'black' : 'lightgrey', lineHeight: '0.9' }}>▼</span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableHeader}
                                       align="center">Spacer<br/>("g" + 19bp)</TableCell>
                            <TableCell className={classes.tableHeader} align="center">RTT Length</TableCell>
                            <TableCell className={classes.tableHeader} align="center">PBS Length</TableCell>
                            <TableCell className={classes.tableHeader} align="center">Edit Position <br/> (to nick)</TableCell>
                            <TableCell className={classes.tableHeader} align="center">Target
                                strand</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row, i) => {
                            return (
                                <TableRow
                                    key={row.HEK_rank}  // Use HEK_rank as the key
                                    onClick={() => handleClickPRIDICT(row.HEK_rank)}

                                    selected={row.selected}
                                    style={{
                                        backgroundColor: row.selected
                                            ?
                                            "#FBE5D6"
                                            :
                                            i % 2 === 0 ? "#FFF" : theme.palette.secondary.main,

                                        color:"#FFF"
                                    }}

                                    classes={{selected: classes.selected}}
                                    className={classes.tableRow}
                                >
                                    <TableCell align="center">
                                        <BlackCheckbox checked={row.selected} onChange={() => handleClickPRIDICT(row.HEK_rank)} />
                                    </TableCell>
                                    <TableCell align="center">{round(row[firstKey], 2)}</TableCell>
                                    <TableCell align="center">{round(row[secondKey], 2)}</TableCell>
                                    <TableCell align="center">{row["Protospacer-Sequence"]}</TableCell>
                                    <TableCell align="center">{row["RTlength"]}</TableCell>
                                    <TableCell align="center">{row["PBSlength"]}</TableCell>
                                    <TableCell align="center">{row["Editing_Position"]}</TableCell>
                                    <TableCell align="center">{row["Target-Strand"]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div><br/></div>
            <Button
                size={"large"}
                variant={"contained"}
                className={classes.buttonStylesBlue}
                onClick={getZipFile}
            >
                Download Results
            </Button>
            <div><br/></div>
            {showNickingTable && <BsArrowDownCircle size={28}/>}

        </div>


    );
}

export default RNATable;
